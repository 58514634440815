import type { ChangeObject, StateObject, SystemObject } from './types'

export default class WebsocketService {
  public static instantiate(
    connectionString: string,
    stateHandler: (data: StateObject) => void,
    changeHandler: (data: ChangeObject) => void,
    systemHandler: (data: SystemObject) => void,
  ) {
    return new WebsocketService(
      connectionString,
      stateHandler,
      changeHandler,
      systemHandler,
    )
  }

  private client: WebSocket | null = null

  private connecting = false

  private reconnectTimeout = 5000

  private reconnectTimer: ReturnType<typeof setTimeout> | null = null

  constructor(
    private connectionString: string,
    private stateHandler: (data: StateObject) => void,
    private changeHandler: (data: ChangeObject) => void,
    private systemHandler: (data: SystemObject) => void,
  ) {}

  private syncHandler(parsedObject: ChangeObject) {}

  public reconnect() {
    if (this.connecting) {
      console.log('Already connecting')
    }
    this.connecting = true
    console.log('*** AP Sync Client: Reconnecting...')
    if (this.reconnectTimer) clearTimeout(this.reconnectTimer)
    this.reconnectTimeout *= 2
    if (this.client != null) {
      this.client.close()
    }
    this.client = null
    this.reconnectTimer = setTimeout(
      () => this.connect(),
      this.reconnectTimeout,
    )
    console.log(
      `*** AP Sync Client: Reconnecting in ${
        this.reconnectTimeout / 1000
      } seconds`,
    )
  }

  private socketOpenHandler = () => {
    console.log('*** AP Sync Client: Client is now connected')
    this.connecting = false
    this.reconnectTimeout = 5000
    if (this.reconnectTimer != null) {
      clearTimeout(this.reconnectTimer)
    }
  }

  private socketErrorHandler = (err: Event) => {
    console.log('*** AP Sync Client Error: ', err)
    if (this.client != null && this.client.readyState !== WebSocket.OPEN) {
      this.reconnect()
    }
  }

  private socketCloseHandler = () => {
    console.log('*** AP Sync Client: Client Closed')
    if (!this.connecting) {
      this.reconnect()
    }
  }

  private socketMessageHandler = (msg: MessageEvent) => {
    try {
      const parsedObject = JSON.parse(msg.data)
      switch (parsedObject.type) {
        case 'sync':
          this.syncHandler(parsedObject)
          break
        case 'change':
          this.changeHandler(parsedObject)
          break
        case 'state':
          this.stateHandler(parsedObject)
          break
        case 'system':
          this.systemHandler(parsedObject)
          break
        default:
          console.log('*** AP Sync Client: Received unknown message.')
          break
      }
    } catch (e) {
      console.log('*** AP Sync Client: Failed to parse message JSON. \n', e)
    }
  }

  public connect() {
    if (this.client != null) {
      this.client.close()
      this.client = null
    }
    console.log('*** AP Sync Client: Connecting...')
    this.client = new WebSocket(this.connectionString)
    this.client.onopen = this.socketOpenHandler
    this.client.onerror = this.socketErrorHandler
    this.client.onclose = this.socketCloseHandler
    this.client.onmessage = this.socketMessageHandler
  }

  public close() {
    if (this.client != null) {
      this.client.close()
    }
  }

  send(msg: string) {
    try {
      // console.log("*** AP Sync Client: WS Client sending:", msg);
      if (this.client != null && this.client.readyState === WebSocket.OPEN) {
        this.client.send(msg)
      } else {
        console.log('*** AP Sync Client: Connection Closed - Cannot Send')
      }
    } catch (err) {
      console.log('*** AP Sync Client: Error sending: ', err)
    }
  }
}

import * as Sentry from '@sentry/browser'

type SentryIdentifiers = {
  readonly id?: string
  readonly ip_address?: string
  readonly email?: string
  readonly username?: string
}

/**
 * Update the information used by Sentry from the current authentication
 * state.
 *
 * @category Sentry
 * @see {@link sentryInit}
 */
export const setSentryIdentifiers = async (
  identifiers: SentryIdentifiers | undefined,
) => {
  {
    const scope = Sentry.getCurrentScope()
    if (identifiers) {
      scope.setUser(identifiers)
    } else {
      scope.clear()
    }
  }

  return identifiers
}

/**
 * Clear Sentry identifiers.
 *
 * @category Sentry
 * @see {@link sentryInit}
 */
export const clearSentryIdentifiers = () => {
  Sentry.getCurrentScope().clear()
}

/**
 * Standardised Sentry browser integration.
 *
 * This should be called before the root React application is mounted.
 *
 * The DSN, release and environment will all be set from environment.
 * The following keys should be defined:
 *
 * - `VITE_SENTRY_DSN`
 * - `VITE_SENTRY_RELEASE`
 * - `VITE_SENTRY_ENVIRONMENT`
 *
 * @category Sentry
 * @see {@link setSentryIdentifiers}
 */
export const sentryInit = (
  options?: Omit<Sentry.BrowserOptions, 'dsn' | 'release'>,
) => {
  const dsn = import.meta.env.VITE_SENTRY_DSN
  const release = import.meta.env.VITE_SENTRY_RELEASE
  const environment = import.meta.env.VITE_SENTRY_ENVIRONMENT

  if (dsn) {
    if (!release) {
      console.warn(
        'Sentry initialisation was not provided a release identifier.',
      )
    }

    Sentry.init({
      dsn,
      release: release || undefined,
      environment,
      integrations: [Sentry.browserTracingIntegration()],
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
      ],
      denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
      debug: import.meta.env.DEV,

      ...options,
    })
  } else {
    // notify through the console if initialisation was skipped, rather than being silent.

    console.warn('Sentry initialisation failed. DSN not provided.')
  }
}

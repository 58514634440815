import { Auth } from '@aws-amplify/auth'

/** Type wrapper for a ref-type similar to React's */
type Ref<T> = { current: T | null }

// A reference to any in-flight refresh-token promise
const _getAccessTokenRef: Ref<Promise<string | undefined>> = { current: null }

/**
 * Internal refresh function that's called by {@link getAccessToken}.
 */
const _getAccessToken = async () => {
  try {
    // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically)
    const session = await Auth.currentSession()
    return session.getAccessToken().getJwtToken()
  } catch (exc) {
    console.warn('Could not get accessToken:', exc)
    return undefined
  }
}

/**
 * Returns the user's JWT access token.
 *
 * Will trigger a token refresh if that's required.
 *
 * If the token refresh fails (because it too is out of date, the saga that
 * is monitoring the Cognito state will emit an event and the user's state
 * in Redux will be updated to unauthenticated, which will prompt the sign-in
 * page again).
 *
 * This promise is idempotent. Multiple calls to `getAccessToken` will return
 * the same promise, until that promise is resolved/rejected.
 *
 * @returns the updated access token
 * @category HTTP
 */
export const getAccessToken = async () => {
  if (_getAccessTokenRef.current) {
    // There's an existing promise in flight, simply return that
    return _getAccessTokenRef.current
  }

  try {
    // We need to start a new promise
    _getAccessTokenRef.current = _getAccessToken()
    return await _getAccessTokenRef.current
  } finally {
    _getAccessTokenRef.current = null
  }
}

import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { getIsAuthenticated, useAuthState } from 'hooks/useAuthState'
import { EsConfig } from 'schemas/eventsync-config'
import { StoreKeys } from 'store/types'
import { API_DOMAIN } from 'utils/httpRest'
import { jwtFetch } from 'utils/jwtFetch'
import { WorkflowEsconfigSchema } from 'utils/workflowSchema'
import type { WorkflowEsConfig } from 'utils/workflowSchema'

type QueryResult = UseQueryResult<
  {
    sockets: { preview_url: string; public_url: string }
    app_urls: { preview_url: string; public_url: string }
  } & WorkflowEsConfig
>

/**
 *
 * Get the ES config for the app to be loaded into form
 * state.
 */
export const useEsConfig = (runStore?: boolean): QueryResult => {
  const authState = useAuthState()
  const isAuthenticated = getIsAuthenticated(authState)

  return useQuery({
    queryKey: [StoreKeys.ES_CONFIG],
    queryFn: async () => {
      try {
        const response = await jwtFetch(
          `${API_DOMAIN}/land/`,
          'GET',
          undefined,
        )
        if (!response.ok) {
          const json = await response.json()
          throw json || { code: response.status, detail: response.statusText }
        }
        const json = await response.json()
        const parsed = WorkflowEsconfigSchema(EsConfig).parse(
          json.active_config,
        )
        const { preview_url, public_url } = z
          .object({
            preview_url: z.string(),
            public_url: z.string(),
          })
          .parse(json.event.connection_information)
        const {
          preview_url: event_preview_url,
          public_url: event_public_url,
        } = z
          .object({
            preview_url: z.string(),
            public_url: z.string(),
          })
          .parse(json.event.pwa_information)
        return {
          ...parsed,
          sockets: {
            preview_url: preview_url,
            public_url: public_url,
          },
          app_urls: {
            preview_url: event_preview_url,
            public_url: event_public_url,
          },
        }
      } catch (error) {
        console.error('Error fetching ES config', error)
        throw error
      }
    },
    enabled: runStore ? runStore && isAuthenticated : isAuthenticated,
    retry: 1,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}

import type { ReactNode } from 'react'

import styled, { css } from 'styled-components'

import { AuthenticationStatus } from '../types'

import { Loader } from 'components/Loader'
import { useAuthState } from 'hooks/useAuthState'
import { pxToRem, theme } from 'theme/utils'

const AuthStatusGuardAppContainer = styled.div.attrs({
  className: 'flex w-full',
})<{ $isLoading: boolean }>`
  ${({ $isLoading }) =>
    $isLoading &&
    css`
      display: none;
    `}
`

const AuthStatusGuardOverlay = styled.div.attrs({
  className:
    'absolute inset-0 z-floating flex h-full items-center justify-center bg-grey100',
})`
  // Only visible in development
  pre {
    overflow: auto;
    padding: ${pxToRem(16)};
    background-color: ${theme('color.grey10')};
  }
`

export type AuthStatusGuardProps = {
  readonly fullScreen?: boolean
  /**
   * `status` will determine what should be displayed depending on the status given.
   */
  readonly className?: string
  readonly children?: ReactNode | ReactNode[]
}

/**
 * Auth Status guard is used to indicate the authentication status that effects its children.
 */

const BaseAuthStatusGuard = ({
  children,
  className,
  ...props
}: AuthStatusGuardProps): JSX.Element => {
  const { status } = useAuthState()

  const isLoading = status === AuthenticationStatus.Verifying

  return (
    <>
      {/* The react components must render so the application
      doesn't get in an infinite login loop, so we just don't
      display it with css */}
      <AuthStatusGuardAppContainer $isLoading={isLoading}>
        {children}
      </AuthStatusGuardAppContainer>
      {isLoading && (
        <AuthStatusGuardOverlay>
          <Loader color="white" backgroundColor="grey100" />
        </AuthStatusGuardOverlay>
      )}
    </>
  )
}

export const AuthStatusGuard = styled(BaseAuthStatusGuard)``

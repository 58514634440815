import { Suspense, useEffect, useMemo, useRef, useState } from 'react'

import type { Location } from 'react-router-dom'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import { AuthStatusGuard } from 'views/login/components'

const routes = [
  {
    path: '/',
    lazy: () => import('views/dashboard'),
  },
  {
    path: '/audio',
    lazy: () => import('views/audio'),
  },
  {
    path: '/content',
    lazy: () => import('views/content'),
  },
  {
    path: '/links',
    lazy: () => import('views/links'),
  },
  {
    path: '/theme',
    lazy: () => import('views/theme'),
  },
  {
    path: '/login',
    lazy: () => import('views/login/Login'),
  },
  {
    path: '/forgot-password/:email/:code?',
    lazy: () => import('views/login/ForgottenPassword/ResetPassword'),
  },
  {
    path: '/forgot-password/*',
    lazy: () => import('views/login/ForgottenPassword'),
  },
  {
    path: '/forgot-password',
    lazy: () => import('views/login/ForgottenPassword'),
  },
  {
    path: '/verify-email/:code?',
    lazy: () => import('views/login/VerifyEmail'),
  },
  {
    path: '/logout',
    lazy: () => import('views/login/Logout'),
  },
  {
    path: '/unavailable',
    lazy: () => import('views/errors/CatchAllFullpage'),
  },
  {
    path: '*',
    lazy: () => import('views/errors/NotFound'),
  },
]

/**
 * The central router.
 */
export const Routes = (): JSX.Element | null => {
  const [currentRoute, setCurrentRoute] = useState('')
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const router = useMemo(
    () =>
      createBrowserRouter(routes, {
        future: {
          v7_fetcherPersist: true,
          v7_normalizeFormMethod: true,
          v7_partialHydration: true,
          v7_relativeSplatPath: true,
          v7_skipActionErrorRevalidation: true,
        },
      }),
    [],
  )

  const stateRun = ({ location }: { location: Location }): void => {
    const fullURL = `${location.pathname}${location.search}`
    if (fullURL !== currentRoute) {
      // Clear the previous timeout if it exists
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
      // Set a new timeout and store the ID in the ref
      timerRef.current = setTimeout(() => {
        setCurrentRoute(fullURL)
        // Use a long timeout to allow for page title changes.
        // This is a workaround for the fact that the title is not
        // updated immediately when the route changes.
      }, 500)
    }
  }
  useEffect(() => {
    router.subscribe(stateRun)
    // Cleanup function to clear the timeout and unsubscribe from the router
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoute, document.title])

  return (
    <AuthStatusGuard fullScreen>
      <Suspense fallback={<div />}>
        <RouterProvider router={router} />
      </Suspense>
    </AuthStatusGuard>
  )
}

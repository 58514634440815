/* eslint-disable */
import * as z from 'zod'
import { camel, snake } from 'radash'

import {
  Struct,
  Entity,
  Meta,
  recase,
  CasingFunction,
  GazelleRef,
  GazelleRefSchema,
} from '@apsys/gazelle'
import { NestedEntity, NestedEntitySchema } from '@apsys/gazelle'

import * as VixenCore from 'schemas/vixen-core'

export const meta: Meta = {
  name: 'Vixen Assets',
  moduleName: 'vixen-assets',
  version: '0.10.0',
  authors: ['Art Processors <developers@artprocessors.net>'],
}

// === enums ===
// === interfaces ===
// --- asset-group ---
export interface AssetGroupSchema extends VixenCore.NamedSchema {
  assets: Array<GazelleRefSchema | NestedEntitySchema>
  external_id?: string | null
}

export interface IAssetGroup extends VixenCore.INamed {
  assets: Array<GazelleRef<IAsset> | NestedEntity<IAsset>>
  external_id?: string
}

// --- asset ---
export interface AssetSchema {
  url: string
  content_type: string
  language?: string | null
  copyright?: string | null
  distribution_control: 'unrestricted' | 'onsite' | 'admin-only'
  external_id?: string | null
}

export interface IAsset {
  url: string
  content_type: string
  language?: string
  copyright?: string
  distribution_control: 'unrestricted' | 'onsite' | 'admin-only'
  external_id?: string
}

// --- spatial-asset ---
export interface SpatialAssetSchema extends AssetSchema {
  width: number
  height: number
}

export interface ISpatialAsset extends IAsset {
  width: number
  height: number
}

// --- temporal-asset ---
export interface TemporalAssetSchema extends AssetSchema {
  duration?: number | null
  subtitle_languages: Array<string>
}

export interface ITemporalAsset extends IAsset {
  duration?: number
  subtitle_languages: Array<string>
}

// --- image-asset ---
export interface ImageAssetSchema extends AssetSchema {}

export interface IImageAsset extends IAsset {}

// === structs ===
export interface PointSchema {
  x: number
  y: number
}

export interface IPoint {
  x: number
  y: number
}

type PointSchemaType = z.Schema<Point, z.ZodTypeDef, PointSchema>

export const PointSchema = z
  .lazy(() =>
    z.object({
      x: z.number(),
      y: z.number(),
    }),
  )
  .transform(
    value => new Point(recase(value, snake)),
  ) satisfies PointSchemaType

export class Point extends Struct<PointSchema> implements IPoint {
  static readonly typename = 'point'
  static readonly schema: PointSchemaType = PointSchema
  static readonly parse = PointSchema.parse
  static readonly fields = ['x', 'y']
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'point'

  x!: number
  y!: number

  constructor(attrs: IPoint) {
    super(attrs)
  }
}

export interface BoxSchema {
  top_left: PointSchema
  bottom_right: PointSchema
}

export interface IBox {
  top_left: Point
  bottom_right: Point
}

type BoxSchemaType = z.Schema<Box, z.ZodTypeDef, BoxSchema>

export const BoxSchema = z
  .lazy(() =>
    z.object({
      top_left: PointSchema,
      bottom_right: PointSchema,
    }),
  )
  .transform(value => new Box(recase(value, snake))) satisfies BoxSchemaType

export class Box extends Struct<BoxSchema> implements IBox {
  static readonly typename = 'box'
  static readonly schema: BoxSchemaType = BoxSchema
  static readonly parse = BoxSchema.parse
  static readonly fields = ['top_left', 'bottom_right']
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'box'

  top_left!: Point
  bottom_right!: Point

  constructor(attrs: IBox) {
    super(attrs)
  }
}

// === entities ===
export interface PictureSchema
  extends AssetGroupSchema,
    VixenCore.RoutableSchema {
  assets: Array<GazelleRefSchema | NestedEntitySchema>
  alt_text: Record<string, string>
}

export interface IPicture extends IAssetGroup, VixenCore.IRoutable {
  assets: Array<GazelleRef<IImageAsset> | NestedEntity<IImageAsset>>
  alt_text: Record<string, string>
}

type PictureSchemaType = z.Schema<Picture, z.ZodTypeDef, PictureSchema>

export const PictureSchema = z
  .lazy(() =>
    z.object({
      name: z.record(z.string()),
      assets: z.array(z.union([GazelleRefSchema, NestedEntitySchema])),
      external_id: z.string().nullish(),
      alt_text: z.record(z.string()),
    }),
  )
  .transform(
    value => new Picture(recase(value, snake)),
  ) satisfies PictureSchemaType

export class Picture extends Entity<PictureSchema> implements IPicture {
  static readonly typename = 'picture'
  static readonly schema: PictureSchemaType = PictureSchema
  static readonly parse = PictureSchema.parse
  static readonly fields = ['name', 'assets', 'external_id', 'alt_text']
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'picture'

  name!: Record<string, string>
  assets!: Array<GazelleRef<IImageAsset> | NestedEntity<IImageAsset>>
  external_id?: string
  alt_text!: Record<string, string>

  constructor(attrs: IPicture) {
    super(attrs)
  }
}

export interface VideoSchema
  extends AssetGroupSchema,
    VixenCore.RoutableSchema {
  assets: Array<GazelleRefSchema | NestedEntitySchema>
  preview_image: Array<GazelleRefSchema | NestedEntitySchema>
  transcript?: GazelleRefSchema | NestedEntitySchema | null
}

export interface IVideo extends IAssetGroup, VixenCore.IRoutable {
  assets: Array<GazelleRef<VideoAsset> | NestedEntity<VideoAsset>>
  preview_image: Array<GazelleRef<IImageAsset> | NestedEntity<IImageAsset>>
  transcript?:
    | GazelleRef<VixenCore.IContent>
    | NestedEntity<VixenCore.IContent>
}

type VideoSchemaType = z.Schema<Video, z.ZodTypeDef, VideoSchema>

export const VideoSchema = z
  .lazy(() =>
    z.object({
      name: z.record(z.string()),
      assets: z.array(z.union([GazelleRefSchema, NestedEntitySchema])),
      external_id: z.string().nullish(),
      preview_image: z.array(z.union([GazelleRefSchema, NestedEntitySchema])),
      transcript: z.union([GazelleRefSchema, NestedEntitySchema]).nullish(),
    }),
  )
  .transform(
    value => new Video(recase(value, snake)),
  ) satisfies VideoSchemaType

export class Video extends Entity<VideoSchema> implements IVideo {
  static readonly typename = 'video'
  static readonly schema: VideoSchemaType = VideoSchema
  static readonly parse = VideoSchema.parse
  static readonly fields = [
    'name',
    'assets',
    'external_id',
    'preview_image',
    'transcript',
  ]
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'video'

  name!: Record<string, string>
  assets!: Array<GazelleRef<VideoAsset> | NestedEntity<VideoAsset>>
  external_id?: string
  preview_image!: Array<GazelleRef<IImageAsset> | NestedEntity<IImageAsset>>
  transcript?:
    | GazelleRef<VixenCore.IContent>
    | NestedEntity<VixenCore.IContent>

  constructor(attrs: IVideo) {
    super(attrs)
  }
}

export interface AudioSchema extends AssetGroupSchema {
  assets: Array<GazelleRefSchema | NestedEntitySchema>
  transcript?: GazelleRefSchema | NestedEntitySchema | null
}

export interface IAudio extends IAssetGroup {
  assets: Array<GazelleRef<AudioAsset> | NestedEntity<AudioAsset>>
  transcript?:
    | GazelleRef<VixenCore.IContent>
    | NestedEntity<VixenCore.IContent>
}

type AudioSchemaType = z.Schema<Audio, z.ZodTypeDef, AudioSchema>

export const AudioSchema = z
  .lazy(() =>
    z.object({
      name: z.record(z.string()),
      assets: z.array(z.union([GazelleRefSchema, NestedEntitySchema])),
      external_id: z.string().nullish(),
      transcript: z.union([GazelleRefSchema, NestedEntitySchema]).nullish(),
    }),
  )
  .transform(
    value => new Audio(recase(value, snake)),
  ) satisfies AudioSchemaType

export class Audio extends Entity<AudioSchema> implements IAudio {
  static readonly typename = 'audio'
  static readonly schema: AudioSchemaType = AudioSchema
  static readonly parse = AudioSchema.parse
  static readonly fields = ['name', 'assets', 'external_id', 'transcript']
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'audio'

  name!: Record<string, string>
  assets!: Array<GazelleRef<AudioAsset> | NestedEntity<AudioAsset>>
  external_id?: string
  transcript?:
    | GazelleRef<VixenCore.IContent>
    | NestedEntity<VixenCore.IContent>

  constructor(attrs: IAudio) {
    super(attrs)
  }
}

export interface RasterImageAssetSchema
  extends SpatialAssetSchema,
    ImageAssetSchema {
  focus_area?: BoxSchema | null
}

export interface IRasterImageAsset extends ISpatialAsset, IImageAsset {
  focus_area?: Box
}

type RasterImageAssetSchemaType = z.Schema<
  RasterImageAsset,
  z.ZodTypeDef,
  RasterImageAssetSchema
>

export const RasterImageAssetSchema = z
  .lazy(() =>
    z.object({
      url: z.string(),
      content_type: z.string(),
      language: z.string().nullish(),
      copyright: z.string().nullish(),
      distribution_control: z.enum(['unrestricted', 'onsite', 'admin-only']),
      external_id: z.string().nullish(),
      width: z.number(),
      height: z.number(),
      focus_area: BoxSchema.nullish(),
    }),
  )
  .transform(
    value => new RasterImageAsset(recase(value, snake)),
  ) satisfies RasterImageAssetSchemaType

export class RasterImageAsset
  extends Entity<RasterImageAssetSchema>
  implements IRasterImageAsset
{
  static readonly typename = 'raster-image-asset'
  static readonly schema: RasterImageAssetSchemaType = RasterImageAssetSchema
  static readonly parse = RasterImageAssetSchema.parse
  static readonly fields = [
    'url',
    'content_type',
    'language',
    'copyright',
    'distribution_control',
    'external_id',
    'width',
    'height',
    'focus_area',
  ]
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'raster-image-asset'

  url!: string
  content_type!: string
  language?: string
  copyright?: string
  distribution_control!: 'unrestricted' | 'onsite' | 'admin-only'
  external_id?: string
  width!: number
  height!: number
  focus_area?: Box

  constructor(attrs: IRasterImageAsset) {
    super(attrs)
  }
}

export interface VectorImageAssetSchema
  extends SpatialAssetSchema,
    ImageAssetSchema {}

export interface IVectorImageAsset extends ISpatialAsset, IImageAsset {}

type VectorImageAssetSchemaType = z.Schema<
  VectorImageAsset,
  z.ZodTypeDef,
  VectorImageAssetSchema
>

export const VectorImageAssetSchema = z
  .lazy(() =>
    z.object({
      url: z.string(),
      content_type: z.string(),
      language: z.string().nullish(),
      copyright: z.string().nullish(),
      distribution_control: z.enum(['unrestricted', 'onsite', 'admin-only']),
      external_id: z.string().nullish(),
      width: z.number(),
      height: z.number(),
    }),
  )
  .transform(
    value => new VectorImageAsset(recase(value, snake)),
  ) satisfies VectorImageAssetSchemaType

export class VectorImageAsset
  extends Entity<VectorImageAssetSchema>
  implements IVectorImageAsset
{
  static readonly typename = 'vector-image-asset'
  static readonly schema: VectorImageAssetSchemaType = VectorImageAssetSchema
  static readonly parse = VectorImageAssetSchema.parse
  static readonly fields = [
    'url',
    'content_type',
    'language',
    'copyright',
    'distribution_control',
    'external_id',
    'width',
    'height',
  ]
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'vector-image-asset'

  url!: string
  content_type!: string
  language?: string
  copyright?: string
  distribution_control!: 'unrestricted' | 'onsite' | 'admin-only'
  external_id?: string
  width!: number
  height!: number

  constructor(attrs: IVectorImageAsset) {
    super(attrs)
  }
}

export interface VideoAssetSchema
  extends SpatialAssetSchema,
    TemporalAssetSchema,
    VixenCore.PlayableSchema {}

export interface IVideoAsset
  extends ISpatialAsset,
    ITemporalAsset,
    VixenCore.IPlayable {}

type VideoAssetSchemaType = z.Schema<
  VideoAsset,
  z.ZodTypeDef,
  VideoAssetSchema
>

export const VideoAssetSchema = z
  .lazy(() =>
    z.object({
      url: z.string(),
      content_type: z.string(),
      language: z.string().nullish(),
      copyright: z.string().nullish(),
      distribution_control: z.enum(['unrestricted', 'onsite', 'admin-only']),
      external_id: z.string().nullish(),
      duration: z.number().nullish(),
      subtitle_languages: z.array(z.string()),
      width: z.number(),
      height: z.number(),
    }),
  )
  .transform(
    value => new VideoAsset(recase(value, snake)),
  ) satisfies VideoAssetSchemaType

export class VideoAsset
  extends Entity<VideoAssetSchema>
  implements IVideoAsset
{
  static readonly typename = 'video-asset'
  static readonly schema: VideoAssetSchemaType = VideoAssetSchema
  static readonly parse = VideoAssetSchema.parse
  static readonly fields = [
    'url',
    'content_type',
    'language',
    'copyright',
    'distribution_control',
    'external_id',
    'duration',
    'subtitle_languages',
    'width',
    'height',
  ]
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'video-asset'

  url!: string
  content_type!: string
  language?: string
  copyright?: string
  distribution_control!: 'unrestricted' | 'onsite' | 'admin-only'
  external_id?: string
  duration?: number
  subtitle_languages!: Array<string>
  width!: number
  height!: number

  constructor(attrs: IVideoAsset) {
    super(attrs)
  }
}

export interface AudioAssetSchema
  extends TemporalAssetSchema,
    VixenCore.PlayableSchema {}

export interface IAudioAsset extends ITemporalAsset, VixenCore.IPlayable {}

type AudioAssetSchemaType = z.Schema<
  AudioAsset,
  z.ZodTypeDef,
  AudioAssetSchema
>

export const AudioAssetSchema = z
  .lazy(() =>
    z.object({
      url: z.string(),
      content_type: z.string(),
      language: z.string().nullish(),
      copyright: z.string().nullish(),
      distribution_control: z.enum(['unrestricted', 'onsite', 'admin-only']),
      external_id: z.string().nullish(),
      duration: z.number().nullish(),
      subtitle_languages: z.array(z.string()),
    }),
  )
  .transform(
    value => new AudioAsset(recase(value, snake)),
  ) satisfies AudioAssetSchemaType

export class AudioAsset
  extends Entity<AudioAssetSchema>
  implements IAudioAsset
{
  static readonly typename = 'audio-asset'
  static readonly schema: AudioAssetSchemaType = AudioAssetSchema
  static readonly parse = AudioAssetSchema.parse
  static readonly fields = [
    'url',
    'content_type',
    'language',
    'copyright',
    'distribution_control',
    'external_id',
    'duration',
    'subtitle_languages',
  ]
  static readonly casingFunction: CasingFunction = snake

  readonly typename = 'audio-asset'

  url!: string
  content_type!: string
  language?: string
  copyright?: string
  distribution_control!: 'unrestricted' | 'onsite' | 'admin-only'
  external_id?: string
  duration?: number
  subtitle_languages!: Array<string>

  constructor(attrs: IAudioAsset) {
    super(attrs)
  }
}

export type INTERFACES = VixenCore.INTERFACES & {
  'asset-group': IAssetGroup
  asset: IAsset
  'spatial-asset': ISpatialAsset
  'temporal-asset': ITemporalAsset
  'image-asset': IImageAsset
}

export const ENTITIES = {
  ...VixenCore.ENTITIES,
  picture: Picture,
  video: Video,
  audio: Audio,
  'raster-image-asset': RasterImageAsset,
  'vector-image-asset': VectorImageAsset,
  'video-asset': VideoAsset,
  'audio-asset': AudioAsset,
}
export type ENTITIES = {
  [K in keyof typeof ENTITIES]: InstanceType<(typeof ENTITIES)[K]>
}

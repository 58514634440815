import * as z from 'zod'

/**
 * Typed environment returned by `getEnv`.
 *
 * If you want to know if you're a prod or a dev build, use `import.meta.env`.
 * Otherwise use this rather than the untyped `import.meta.env` so that your
 * environment is correctly validated.
 */
const ENV_SCHEMA = z.object({
  /** This is the app title */
  VITE_APP_TITLE: z.string().default('Pladia Event Sync Console'),

  /** AP Standard Deployment Params */
  VITE_CLIENT: z.string().optional(),
  VITE_PROJECT: z.string().optional(),
  VITE_COMPONENT: z.string().optional(),
  VITE_DEPLOY_ENV: z.string().optional(),

  /** Sentry Params */
  VITE_SENTRY_DSN: z.string().url().optional(),
  VITE_SENTRY_RELEASE: z.string().optional(),
  VITE_SENTRY_ENVIRONMENT: z.string().optional(),

  VITE_API_DOMAIN: z.string().optional(),

  /** Rudderstack Params */
  VITE_RUDDERSTACK_WRITE_KEY: z.string().optional(),
  VITE_RUDDERSTACK_DATA_PLANE_URL: z.string().url().optional(),

  /** If set, outputs a menu item to the storybook */
  VITE_STORYBOOK_URL: z.string().optional(),

  /** The app version (the Pladia version, not the package.json version) */
  VITE_APP_RELEASE_VERSION: z
    .string()
    .default('')
    // Strip tag prefixes from the version
    .transform(value => value.replace(/^(release@|v)/, '')),

  /** This is the package.json version */
  VITE_APP_VERSION: z.string().optional(),

  /** Override languages settings */
  VITE_APP_DEFAULT_LANGUAGE: z.string().default('en'),
  VITE_APP_LANGUAGES: z
    .string()
    .default('en;en-US;de;es;es-MX;fr;hi;it;ja;ko;pt;pt-BR;vi;zh-Hans;zh-Hant')
    .transform(value => value.split(';')),
  /** Override the vx-config setting */
  VITE_APP_VX_CONFIG: z
    .string()
    .optional()
    .transform(value => value || undefined),
})

class BadEnvironmentError extends Error {
  name = 'Bad Environment Error'
}

/**
 * Returns the build environment, but only the keys we have validated against
 * the Zod schema {@link ENV_SCHEMA}.
 */
export default (): z.infer<typeof ENV_SCHEMA> => {
  const env = ENV_SCHEMA.safeParse(import.meta.env)

  if (env.success) return env.data

  const message = env.error.errors
    .map(elem => `${elem.path}: ${elem.message}`)
    .join('\n')

  throw new BadEnvironmentError(message)
}

import { DateTime } from 'luxon'
import { z } from 'zod'

import type {
  Entity,
  GazelleEntityFactory,
  GazelleRefSchema,
} from '@apsys/gazelle'

import type { ZOutputSchema } from './zodSchemasSDKConsole'

import type { EsConfig } from 'schemas/eventsync-config'
import {
  RasterImageAsset,
  Picture,
  Audio,
  AudioAsset,
} from 'schemas/vixen-assets'

/**
 * Aliased Shape interface for GazelleRefs,
 * defined for clarity in consumption. This
 * may be rolled into the gazelle package natively
 * later on.
 *
 * `IGazelleRef` has no generic type and can be safely consumed into forms.
 *
 * @category Forms
 */
export interface IGazelleRef extends GazelleRefSchema {}

export type HasRef = {
  readonly typename: string
  readonly uuid: string
}

export type Workflow<G extends Entity<any>> = {
  /** UUID of the entity (Gazelle id) */
  readonly uuid: string
  /** Typename of the entity */
  readonly typename: string
  /** Sequence number (this is the version of the entity) */
  readonly sequence: number
  /** Last updated time of this version */
  readonly updated_at: DateTime
  /** Creation time of this version */
  readonly created_at: DateTime

  /**
   * Gazelle document.
   *
   * There is no `ref` field supplied by Vixen's `document` field.
   * If you need a reference use {@link toGazelleRef} or {@link toIGazelleRef},
   * which will retrieve it from the workflow.
   */
  document: G
}

type LastUpdated = {
  theme: {
    sequence: number
    updated_at: DateTime
  }
  audio: {
    sequence: number
    updated_at: DateTime
  }
  content: {
    sequence: number
    updated_at: DateTime
  }
}

export type WorkflowEsConfig = Workflow<EsConfig> & {
  related_entities: Array<
    | Workflow<Picture>
    | Workflow<RasterImageAsset>
    | Workflow<Audio>
    | Workflow<AudioAsset>
  >
  section_details: LastUpdated
  published_config: {
    section_details: LastUpdated
  }
  section_published: {
    theme: boolean
    audio: boolean
    content: boolean
  }
}

/**
 * @internal
 * @see {@link WorkflowSchema}
 */
export const _WorkflowSchema = z.object({
  uuid: z.string(),
  typename: z.string(),
  sequence: z.number().int(),
  created_at: z.string(),
  updated_at: z.string(),
})

/**
 * Factory to return a parsing function for a given Workflow
 *
 * Use this, or a function like it whenever consuming data from Vixen, to
 * received decoded and unwrapped data.
 *
 * This parser changes the case into a more Typescript friendly camelCase. You
 * can undo these changes with {@link SerializeWorkflow}.
 *
 *
 * @param klass Gazelle entity class
 * @param workflowSchema additional fields to parse (used to extend the schema)
 * @template G Gazelle entity type class (inferred from `klass`). Don't set this, let it be inferred!
 * @category Workflow
 * @see {@link Workflow}
 */
export const WorkflowSchema = <G extends Entity<any>>(
  klass: GazelleEntityFactory<G>,
  workflowSchema = {},
) =>
  z.lazy(() =>
    _WorkflowSchema.extend({
      typename: z.literal(klass.typename),
      document: klass.schema as unknown as ZOutputSchema<G>,
      // We don't transform this sooner because _WorkflowSchema is also included
      // in the related maps.
      created_at: z.string().transform(value => DateTime.fromISO(value)),
      // We don't transform this sooner because _WorkflowSchema is also included
      // in the related maps.
      updated_at: z.string().transform(value => DateTime.fromISO(value)),
      // Extra keys
      ...workflowSchema,
    }),
  )

const LastUpdatedSchema = () =>
  z.object({
    theme: z.object({
      sequence: z.number().int(),
      updated_at: z.string().transform(value => DateTime.fromISO(value)),
    }),
    audio: z.object({
      sequence: z.number().int(),
      updated_at: z.string().transform(value => DateTime.fromISO(value)),
    }),
    content: z.object({
      sequence: z.number().int(),
      updated_at: z.string().transform(value => DateTime.fromISO(value)),
    }),
  })

export const WorkflowEsconfigSchema = (
  klass: GazelleEntityFactory<EsConfig>,
  workflowSchema = {},
) =>
  z.lazy(() =>
    _WorkflowSchema.extend({
      typename: z.literal(klass.typename),
      document: klass.schema as ZOutputSchema<EsConfig>,
      // We don't transform this sooner because _WorkflowSchema is also included
      // in the related maps.
      created_at: z.string().transform(value => DateTime.fromISO(value)),
      // We don't transform this sooner because _WorkflowSchema is also included
      // in the related maps.
      updated_at: z.string().transform(value => DateTime.fromISO(value)),
      related_entities: z.array(
        _WorkflowSchema.passthrough().transform(value => {
          if (value.typename === Picture.typename) {
            return WorkflowSchema(Picture).parse(value)
          }
          if (value.typename === RasterImageAsset.typename) {
            return WorkflowSchema(RasterImageAsset).parse(value)
          }
          if (value.typename === Audio.typename) {
            return WorkflowSchema(Audio).parse(value)
          }
          if (value.typename === AudioAsset.typename) {
            return WorkflowSchema(AudioAsset).parse(value)
          }
          return value
        }),
      ),
      section_details: LastUpdatedSchema(),
      published_config: z.object({
        section_details: LastUpdatedSchema(),
      }),
      section_published: z.object({
        theme: z.boolean(),
        audio: z.boolean(),
        content: z.boolean(),
      }),
      // Extra keys
      ...workflowSchema,
    }),
  )

import type { HttpVerbs } from './httpRest'
import { assemblePayload } from './httpRest'
import { getAccessToken } from './jwt'

/**
 * A wrapper around `fetch` that refreshes the access token if the request
 * fails.
 *
 *
 * @param url URL to fetch
 * @param verb HTTP verb/method
 * @param payload HTTP body
 * @param retries number of retries to refresh the token
 * @template P payload type
 * @category HTTP
 */
export async function jwtFetch<P>(
  url: string,
  verb: HttpVerbs,
  payload: P,
): ReturnType<typeof fetch> {
  const accessToken = await getAccessToken()
  const request = assemblePayload<P>({
    payload,
    verb,
    accessToken,
  })
  const response = await fetch(url, request)
  return response
}

import type { DefaultTheme } from 'styled-components'

import { pxToRem } from './utils'

interface ThemeProps<T extends DefaultTheme> {
  theme: T
}

export type ThemedStyledProps<P extends object, T extends DefaultTheme> = P &
  ThemeProps<T>

/**
 * Base design system colors, matching the design system colors in Figma.
 * https://www.figma.com/design/fmNBTxeMwxbfYUn7upicIp/%F0%9F%9F%A6-Pladia-Console-%7C-VORM-Design-System-v1.0
 *
 */
export const VORMBaseColors = {
  // Blue
  blue10: '#E7E7FF',
  blue20: '#CFCFFF',
  blue30: '#9F9FFF',
  blue40: '#6E6EFF',
  blue50: '#3E3EFF',
  blue60: '#0E0EFF',
  blue70: '#0D0ECC',
  blue80: '#080B99',
  blue90: '#060666',
  blue100: '#030333',

  // Blue Hover
  blueHover10: '#DBDBF2',
  blueHover20: '#C5C5F2',
  blueHover30: '#9797F2',
  blueHover40: '#6969F2',
  blueHover50: '#3B3BF2',
  blueHover60: '#1A1AFF',
  blueHover70: '#1717CF',
  blueHover80: '#14149E',
  blueHover90: '#12126E',
  blueHover100: '#10103D',

  // Grey
  grey10: '#F4F4F4',
  grey20: '#E9E8E9',
  grey30: '#D2D2D2',
  grey40: '#BCBBBB',
  grey50: '#A5A5A9',
  grey60: '#8F8E93',
  grey70: '#727276',
  grey80: '#565558',
  grey90: '#39393B',
  grey100: '#1D1C1D',

  // Grey Hover
  greyHover10: '#DCDCDC',
  greyHover20: '#D2D1D2',
  greyHover30: '#BDBDBF',
  greyHover40: '#AAA9AB',
  greyHover50: '#959599',
  greyHover60: '#9A999D',
  greyHover70: '#808083',
  greyHover80: '#666668',
  greyHover90: '#4C4C4E',
  greyHover100: '#333233',

  // Red
  // Error uses the red70 color
  error: '#CC3A3A',
  red10: '#FFEDED',
  red20: '#FFDBDB',
  red30: '#FFB6B6',
  red50: '#FF4949',
  red60: '#FF606D',
  red70: '#CC3A3A',
  red80: '#992C2C',

  // Red Hover
  redHover20: '#F2D0D0',
  redHover30: '#F2ADAD',
  redHover50: '#F26868',
  redHover60: '#F24545',
  redHover70: '#C23737',

  // Amber
  // Warning uses the amber60 color
  warning: '#FF9549',
  amber10: '#FFF4ED',
  amber20: '#FFEADB',
  amber30: '#FFD5B6',
  amber50: '#FFAA6D',
  amber60: '#FF9549',
  amber70: '#CC773A',

  // Amber Hover
  amberHover20: '#F2DED0',
  amberHover30: '#F2CAAD',
  amberHover50: '#F2A168',
  amberHover60: '#FF9A52',
  amberHover70: '#C7E74E',

  // Green
  // Success uses the green60 color
  success: '#00934B',
  green10: '#E5F4ED',
  green20: '#CCE9DB',
  green30: '#99D4B7',
  green50: '#33A96F',
  green60: '#00934B',
  green70: '#00763C',
  green80: '#085B32',

  // Green Hover
  greenHover10: '#DAEBE1',
  greenHover20: '#C2DDD0',
  greenHover30: '#91C9AE',
  greenHover50: '#30A169',
  greenHover60: '#0D9854',
  greenHover70: '#0D7D46',

  // Black
  black: '#000000',

  // White
  white: '#FFFFFF',

  // Transparent and inherit here as sometimes we need to pass those in
  transparent: 'transparent',
  inherit: 'inherit',
} as const

export const themeValues = {
  ARROW_RIGHT: '\u2192',
  color: {
    ...VORMBaseColors,
    text: VORMBaseColors.black,
    textLabel: VORMBaseColors.grey70,
    // mop up :
    formLabel: VORMBaseColors.grey70,
    border: VORMBaseColors.grey30,
  },
  font: {
    weight: {
      regular: '400',
      medium: '500',
      semibold: '600',
      bold: '700',
    },
    family: {
      // In order to get the fonts loaded in as quickly
      // as possible, they are imported in the <head> of the
      // consuming application's main html file.
      headings: 'Larsseit, DM Sans, sans-serif',
      base: 'Lato, supria-sans, sans-serif',
      mono: 'IBM Plex Mono, monospace',
      // Used in WYSIWYG styles.
      fallback: 'DM Sans, sans-serif',
    },
    size: {
      xLarge: pxToRem(30),
      large: pxToRem(26),
      medium: pxToRem(22),
      base: pxToRem(16),
      small: pxToRem(14),
      xSmall: pxToRem(12),
      xxSmall: pxToRem(11),
    },
  },
  shadow: {
    spread: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
    xSmall: '0 1px 2px 0 rgba(0, 0, 0, 0.2)',
    small: '0 2px 6px -5px rgba(0, 0, 0, 0.2)',
    medium: '0 9px 14px -10px rgba(0, 0, 0, 0.4)',
    // New naming convention
    // Level 1
    L1: '0px 2px 4px -3px rgba(0, 0, 0, 0.2)',
    L2: '0px 6px 12px -8px rgba(0, 0, 0, 0.4)',
    L3: '4px 4px 20px rgba(0, 0, 0, 0.2)',
    // Hard Level 1
    HL1: '2px 2px 0px rgba(0, 0, 0, 0.1)',
    // Inner Shadows
    // Level 1
    IL1: 'inset 0px 1px 4px -2px rgba(0, 0, 0, 0.2)',
    focusRing: `0 0 0 2px ${VORMBaseColors.blue50}`,
    popover: '0px 4px 4px 0px #0000001a',
  },
  transition: {
    base: 'all 200ms ease-in-out',
    navToggle: 'all 100ms',
  },
  layout: {
    container: {
      maxWidth: '780px',
    },
    list: {
      rowGap: '6px',
    },
  },
  radius: {
    R0: pxToRem(0),
    R2: pxToRem(2),
    R4: pxToRem(4),
    R8: pxToRem(8),
    R15: pxToRem(15),
    R20: pxToRem(20),
    R50: pxToRem(50),
    // This follows the design system
    full: pxToRem(999),
  },
  zIndex: {
    base: 0,
    /** all elements that need to raise above the base z-index: 0 */
    floating: 1,
    /** e.g. a sticky header */
    sticky: 10,
    /** e.g. a background overlay over a map */
    overlay: 100,
    /** e.g. a panel over a map */
    panel: 200,
    /** e.g. a sidebar that needs to be above an overlay */
    sidebar: 300,
    /** e.g. a fullscreen modal dialog */
    fullscreen: 1000,
  },
} as const

/**
 * Base – Some bare essentials to help us when working with color.
 */
export type BaseColors = {
  inherit: string
  transparent: string
}

export const iconSizes = {
  xxLarge: 100,
  xLarge: 60,
  large: 40,
  notQuiteLarge: 32,
  medium: 24,
  small: 16,
  xSmall: 12,
  xxSmall: 10,
  superscript: 8,
}

export type IconSizes = keyof typeof iconSizes

export type ThemeInterface = typeof themeValues

/** A valid base color key in the theme */
export type ThemeBaseColor = keyof BaseColors

export type ThemeColor = keyof ThemeInterface['color']

export type ThemeInterfaceKeys =
  | `color.${ThemeColor}`
  | `transition.${keyof ThemeInterface['transition']}`
  | `font.weight.${keyof ThemeInterface['font']['weight']}`
  | `font.family.${keyof ThemeInterface['font']['family']}`
  | `font.size.${keyof ThemeInterface['font']['size']}`
  | `layout.container.${keyof ThemeInterface['layout']['container']}`
  | `layout.list.${keyof ThemeInterface['layout']['list']}`
  | `shadow.${keyof ThemeInterface['shadow']}`
  | `zIndex.${keyof ThemeInterface['zIndex']}`
  | `radius.${keyof ThemeInterface['radius']}`

export type ThemeFunction<TKey extends keyof ThemeInterface> = <
  K extends keyof ThemeInterface[TKey],
>(
  key: K,
) => (props: ThemedStyledProps<any, ThemeInterface>) => ThemeInterface[TKey][K]

export type ThemeFontFunction<TKey extends keyof ThemeInterface['font']> = <
  K extends keyof ThemeInterface['font'][TKey],
>(
  key: K,
) => (
  props: ThemedStyledProps<any, ThemeInterface>,
) => ThemeInterface['font'][TKey][K]
